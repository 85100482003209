import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const DropdownStyled = styled.div`
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.textPrimary};
    transform: scale(0);
    will-change: transform;
    transform-origin: center left;
    transition: transform 1.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  }

  :last-child {
    :after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.textPrimary};
      transform: scale(0);
      will-change: transform;
      transform-origin: center left;
      transition: transform 1.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
    }
  }

  &.reveal {
    :before {
      transform: scale(1);
    }

    :last-child:after {
      transform: scale(1);
    }
  }

  .dropdown-head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    padding: 1.5% 0.2vw 3%;
    cursor: pointer;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 16px 2px;
    }

    &.no-click {
      cursor: default;

      @media (max-width: ${breakpoints.screenSM}) {
        flex-direction: column;
      }
    }
  }

  .head-title {
    font-family: 'Space Grotesk', sans-serif;
    text-align: left;
    opacity: 0;
    transform: translateY(2vh);
    will-change: transform, opacity;
    transition: transform 1s cubic-bezier(0.22, 0.94, 0.44, 1),
      opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-delay: 0.8s;
    pointer-events: none;

    &.reveal {
      opacity: 1;
      transform: translateY(0);
    }

    span {
      display: block;
      font-weight: 400;
      margin-top: 1%;
    }
  }

  .dropdown-body {
    display: flex;
    flex-direction: column;
    max-height: 0;
    max-width: 36vw;
    margin-left: auto;
    margin-right: 6vw;
    opacity: 0;
    will-change: max-height, transform, opacity;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    @media (max-width: ${breakpoints.screenLG}) {
      padding-top: 20px;
      max-width: 100%;
    }

    &.reveal {
      opacity: 1;
      transform: translateY(-2vw);
    }
  }

  .item-text {
    line-height: 1.8;
    margin-bottom: 1vw;

    @media (max-width: ${breakpoints.screenLG}) {
      line-height: 1.8;
      margin-bottom: 20px;
    }
  }

  .head-icon {
    opacity: 0;
    transform: translateX(2vh);
    will-change: transform, opacity;
    transition: transform 0.6s cubic-bezier(0.22, 0.94, 0.44, 1),
      opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-delay: 2.4s;
    pointer-events: none;

    &.reveal {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .icon {
    position: relative;
    width: 1.6vw;
    height: 1.6vw;
    will-change: transform;
    transform: rotate(0deg);
    transform-origin: center;
    transition: all 0.4s ease-in-out;
    pointer-events: none;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 24px;
      height: 24px;
    }

    &.rotate {
      transform: rotate(180deg);
    }
  }

  .plus {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.6vw;
    height: 1.6vw;
    height: auto;
    will-change: transform, opacity;
    transform: rotate(-90deg);
    transition: all 0.4s ease-in-out;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 24px;
      height: 24px;
    }

    &.rotate {
      transform: rotate(0deg);
    }
  }

  .minus {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.6vw;
    height: auto;

    @media (max-width: ${breakpoints.screenLG}) {
      width: auto;
    }
  }

  .head-btn {
    display: flex;
    align-items: center;
    gap: 1vw;
    opacity: 0;
    transform: translateX(2vh);
    will-change: transform, opacity;
    transition: transform 0.6s cubic-bezier(0.22, 0.94, 0.44, 1),
      opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-delay: 2.4s;

    @media (max-width: ${breakpoints.screenLG}) {
      gap: 15px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      justify-content: flex-end;
      margin-top: 6px;
    }

    &.reveal {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .head-btn-link {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.textPrimary};
    width: 10vw;
    height: 3vw;
    text-transform: uppercase;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 170px;
      height: 46px;
    }

    &.disabled {
      background-color: #e6cfec;
      pointer-events: none;
    }

    :after {
      display: none;
    }
  }

  a {
    font-weight: 600;
    position: relative;
    cursor: pointer;

    :after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: ${({ theme }) => theme.textPrimary};
      height: 0.1em;
      width: 100%;
      transform: scaleX(0);
      will-change: transform;
      transition: transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
      transform-origin: bottom right;
    }

    :hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;

      @media (max-width: ${breakpoints.screenLG}) {
        transform: scaleX(0);
      }
    }
  }

  li {
    font-size: 1.05vw;
    line-height: 1.8;
    letter-spacing: 0.02em;
    font-weight: 400;
    margin-left: 1vw;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 16px;
      line-height: 1.4;
      margin-left: 20px;
    }
  }

  ul {
    padding: 0 0.2vw;
    margin: 0;

    li:last-child {
      margin-bottom: 1.5vw;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-bottom: 20px;
      }
    }
  }

  ol {
    padding: 0 0.2vw;
    margin: 0;

    li {
      margin-bottom: 1.5vw;
    }

    br {
      content: '';
      display: block;
      margin-bottom: 1vw;
    }
  }

  .mb {
    display: block;
    max-width: fit-content;
    margin-bottom: 2%;
  }
`

const DropdownStyledZanzibar = styled.div`
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.textPrimary};
    transform: scale(0);
    will-change: transform;
    transform-origin: center left;
    transition: transform 1.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  }

  :last-child {
    :after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.textPrimary};
      transform: scale(0);
      will-change: transform;
      transform-origin: center left;
      transition: transform 1.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
    }
  }

  &.reveal {
    :before {
      transform: scale(1);
    }

    :last-child:after {
      transform: scale(1);
    }
  }

  .dropdown-head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    padding: 1.5% 0.2vw 3%;
    cursor: pointer;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 16px 2px;
    }
  }

  .head-title {
    text-align: left;
    opacity: 0;
    transform: translateY(2vh);
    will-change: transform, opacity;
    transition: transform 1s cubic-bezier(0.22, 0.94, 0.44, 1),
      opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-delay: 0.2s;
    pointer-events: none;

    &.reveal {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .dropdown-body {
    display: flex;
    flex-direction: column;
    max-height: 0;
    max-width: 33.5vw;
    margin-left: auto;
    margin-right: 6vw;
    opacity: 0;
    will-change: max-height, transform, opacity;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    @media (max-width: ${breakpoints.screenLG}) {
      padding-top: 20px;
      max-width: 100%;
    }

    &.reveal {
      opacity: 1;
      transform: translateY(-2vw);
    }
  }

  .item-text {
    line-height: 1.8;
    margin-bottom: 1vw;

    @media (max-width: ${breakpoints.screenLG}) {
      line-height: 1.8;
      margin-bottom: 20px;
    }
  }

  .head-icon {
    opacity: 0;
    transform: translateX(2vh);
    will-change: transform, opacity;
    transition: transform 0.6s cubic-bezier(0.22, 0.94, 0.44, 1),
      opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-delay: 1.8s;
    pointer-events: none;

    &.reveal {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .icon {
    position: relative;
    width: 1.6vw;
    height: 1.6vw;
    will-change: transform;
    transform: rotate(0deg);
    transform-origin: center;
    transition: all 0.4s ease-in-out;
    pointer-events: none;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 24px;
      height: 24px;
    }

    &.rotate {
      transform: rotate(180deg);
    }
  }

  .plus {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.6vw;
    height: 1.6vw;
    height: auto;
    will-change: transform, opacity;
    transform: rotate(-90deg);
    transition: all 0.4s ease-in-out;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 24px;
      height: 24px;
    }

    &.rotate {
      transform: rotate(0deg);
    }
  }

  .minus {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.6vw;
    height: auto;

    @media (max-width: ${breakpoints.screenLG}) {
      width: auto;
    }
  }

  a {
    font-weight: 600;
    position: relative;

    :after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: ${({ theme }) => theme.textPrimary};
      height: 0.1em;
      width: 100%;
      transform: scaleX(0);
      will-change: transform;
      transition: transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
      transform-origin: bottom right;
    }

    :hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;

      @media (max-width: ${breakpoints.screenLG}) {
        transform: scaleX(0);
      }
    }
  }

  li {
    font-size: 1.05vw;
    line-height: 1.8;
    letter-spacing: 0.02em;
    font-weight: 400;
    margin-left: 1vw;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 16px;
      line-height: 1.4;
      margin-left: 20px;
    }
  }

  ul {
    padding: 0 0.2vw;
    margin: 0;

    li:last-child {
      margin-bottom: 1.5vw;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-bottom: 20px;
      }
    }
  }

  ol {
    padding: 0 0.2vw;
    margin: 0;

    li {
      margin-bottom: 1.5vw;
    }

    br {
      content: '';
      display: block;
      margin-bottom: 1vw;
    }
  }

  .mb {
    display: block;
    max-width: fit-content;
    margin-bottom: 2%;
  }
`

export { DropdownStyled, DropdownStyledZanzibar }
