import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const slideUp = keyframes`
  0% {
    transform: translateY(6vh);
  }
  100% {
    transform: translateY(0);
  }
`

const FestivalAtoZStyled = styled.div`
  height: 100%;

  .content {
    position: relative;
    overflow-x: hidden;
  }

  .inner {
    padding: 27vh 15vw 30vh;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 200px 30px 50px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 150px 20px 0;
    }
  }

  .title {
    line-height: 1.4;
    margin-bottom: 3%;
    opacity: 0;
    will-change: opacity;
    animation: ${fadeIn} 0.8s cubic-bezier(0.7, 0, 0.3, 1) forwards;
    animation-delay: 0.4s;

    @media (max-width: ${breakpoints.screenLG}) {
      line-height: 1.3;
      margin-bottom: 30px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      font-size: 50px;
    }
  }

  .text {
    line-height: 1.8;
    margin-bottom: 3%;
    opacity: 0;
    transform: translateY(6vh);
    will-change: transform, opacity;
    animation: ${slideUp} 1s cubic-bezier(0.22, 0.94, 0.44, 1) forwards,
      ${fadeIn} 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    animation-delay: 0.6s;

    @media (max-width: ${breakpoints.screenLG}) {
      margin-bottom: 30px;
    }

    a {
      font-weight: 600;
      position: relative;

      :after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: ${({ theme }) => theme.textPrimary};
        height: 0.1em;
        width: 100%;
        transform: scaleX(0);
        transform-origin: bottom right;
        will-change: transform;
        transition: transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
      }

      :hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;

        @media (max-width: ${breakpoints.screenLG}) {
          transform: scaleX(0);
        }
      }
    }
  }

  .list {
    padding-top: 1.5%;

    @media (max-width: ${breakpoints.screenLG}) {
      padding-top: 30px;
    }
  }
`

export { FestivalAtoZStyled }
